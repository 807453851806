import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'


import './LogoGame.css'

export default function LogoGame() {
    return(
    <>
    <br />
    <Container>
    <h1 id="pageTitle">Logo Game</h1>
    <br /><br />
    <h3>Introduction</h3>
    <br />
    <p>
        This logo game was created after being inspired by the game “Wordly”. Me and my group wanted to replicate the game, however instead of a random word of the day, we believed a random logo of the day was just as exciting. 
    </p>
    <p>
        The player would have to guess: the logo as well as the decade the logo was used.
    </p>
    <br />
    <h3>Project Overview</h3>
    <br />
    <p>
        Within this project, our instructors wanted us to implement the skills we learned after learning MongoDB, Express, React and Node. They wanted us to have a deployed web page with both a front-end interface and a back-end connected to a database. 
    </p>
    <p>
        After my group and I got together, we came into an agreement that I would be in charge of the front-end and UI/UX Design of the project.
    </p>
    <br />
    <hr />
    <h3>Challenges</h3>
    <br />
    <img src="/images/logoGame.png" width="315px" height= "550px" alt="Logo Game phone rendering" className="phoneImg" img-fluid />
    <br />
    <div className="firstDiv">
    <br />
    <h4>UI Design with React</h4>
    <br />
    <p>
        Aside from my class work, this was my first time designing a UI/UX interface using React while also having a backend database. It was difficult to target an image that was connected to our database. If the server was not operating, an image would not render. So due to that, it was difficult for me to target a specific image.
    </p>
    <br />
    <h5>Solution</h5>
    <p>
        I had to truly sit down to read and study the code that was developed by my partners to be able to start designing. I had to understand what was going on so I could create a selector. After many attempts and breaks, I discovered that I could target specific components and objects by implementing a ‘className’. Even with the database not operating, I discovered that it was still possible to create a selector. Not only that, but I also decided to take this time to learn React-Bootstrap to help design a great UX.
    </p>
    <h5>Result</h5>
    <p>
        With the timeframe that was given to us, I was able to successfully create selectors on images that were saved onto our database. It would lead me to further understand how to create a friendly UI/UX with a database connected and create a minimalistic design that would be easy to use for all ages.
    </p>
    <br />
    </div>
    <br />
    <div className="secondDiv">
    <br />
    <h4>Learning Photoshop</h4>
    <br />
    <img src="../images/prototype.png" width="1050px" height="550px" alt="Prototype on a desktop" className="prototypeImg" img-fluid></img>
    <br />
    <br /><br />
    <p>
        Just like in coding, I had no knowledge in graphic designing. There were times within this project where I could not proceed unless one of my partners finished a task that they were doing. So, I decided to pick up and learn Photoshop to create a background. I wanted to further challenge and educate myself. Learning Photoshop during this project ended up being a lot harder than I had imagined. 
    </p>
    <p>
        Before I began, I did research on what was popular when it comes to background designs. After figuring out what design to do, I I had a hard time just trying to figure out how to create a background. I struggled with how to apply a blur, more specifically a gaussian blur. I aimed for a background that was simplistic and dark that closely resembled Wordly’s simplistic design.
    </p>
    <br />
    <h5>Solutions</h5>
    <p>
        After doing extensive research, I discovered that vibrant colors with contrast are very popular today. After I decided what pattern to make based on popularity, I created a prototype on Figma to get an idea of what I would need to do. Using Photoshop without any knowledge or education proved to be very complicated for me. 
    </p>
    <br />
    <h5>Result</h5>
    <p>
        As a result, on presentation day, my group received overwhelming praise from our peers regarding the simplistic look of the game. Also, as a result I decided to further educate myself on Photoshop due to the immense amount of self-gratification I received after creating something new.
    </p>
    <br />
    </div>
    </Container>
    </>
    )
}