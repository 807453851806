import Container from 'react-bootstrap/Container'

import './XGuard.css'

export default function XGuard() {


    return (
    <>
    <br />
    <Container>
    <h1>X-Guard</h1>
    <h5>A Business Management Software Design for Gyms</h5>
    <hr />
    <br />
    <img src="/images/xguard-intro.png" id="final-design" />
    <br /><br />
    <h3>Overview:</h3>
    <br />
    <p>
    Diamond Art Jiu-Jitsu is an affiliate gym of Atos Jiu-Jitsu based out of San Diego, CA. Within this gym, it uses an online gym management software to help keep track of each student’s training hours, monthly billing, and purchases.</p>
    <p>
    Going into my next personal project, I decided to use a design thinking approach to help discover possible solutions on how to create an application that promotes community and builds a sense of belonging.
    </p>
    <br />
    <hr />
    <br />
    <h3>Defining the Problem:</h3>
    <p>
    As a student of this gym, I realized my gym’s management system does not provide a mobile application to make checking into class easier for its students. We would have to use our own online search engine on our phone and navigate to the webpage, login, and then check in.
    </p>
    <br /><br />
    <h3>Goal:</h3>
    <p>
    How can I create a mobile application that will be easier to use, compared to the gym's current management service?
    </p>
    <br /><br />
    <h3>Understanding the Problem:</h3>
    <p>
    The main competitors are using big named gyms to help launch their product into the market. By using well known gyms on their website, it helps gym owners believe that they’re trustworthy and easy to use. So I decided to use a SWOT analysis to figure out a competitive edge.
    </p>
    <br />
    <hr />
    <h3>Competitive Analysis:</h3>
    <p>
    I began my research by conducting a competitive analysis of two competitors online - Push Press and GymDesk. The analysis includes a SWOT Analysis, app reviews, and marketing strategies. While conducting my research, I also decided to analyze the two competitor’s reviews from its users.
    </p>
    <a href="/images/atos-swot.png" target="_blank">
        <img src="/images/atos-swot.png" id="atos-swot" />
    </a>
    <hr />
    <h3>User Flow:</h3>
    <p>
    I created a user flow to create an idea of the necessary steps and screens it would take to complete the basic tasks to navigate through the application. Most importantly, I wanted to create an idea on how to make checking into a class session easier for the user.
    </p>
    <br />
    <a href="/images/atos-userflow.png" target="_blank">
    <img src="/images/atos-userflow.png" id="atos-userflow" />
    </a>
    <br />< br /><br />
    <h3>Mind Map</h3>
    <a href="/images/X-Guard.png" target="_blank">
        <img src="/images/X-Guard.png" id="xguard-image" />
    </a>
    <hr />
    <br />
    <h3>Low Fidelity Wireframes</h3>
    <br />
    <div className="checking-in-container">
    <p>Checking In:</p>
        <a href="/images/atos-index.png" target="_blank">
            <img src="/images/atos-index.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-check-in.png" target="_blank">
            <img src="/images/atos-check-in.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-check-in1.png" target="_blank">
            <img src="/images/atos-check-in1.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-check-in2.png" target="_blank">
            <img src="/images/atos-check-in2.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-success.png" target="_blank">
            <img src="/images/atos-success.png" width="15%" id="check-in"/>
        </a>
    </div>
    <br />
    <div className="checking-in-container">
        <a href="/images/atos-index.png" target="_blank">
            <img src="/images/atos-index.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-createAccount.png" target="_blank">
            <img src="/images/atos-createAccount.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-navbar.png" target="_blank">
            <img src="/images/atos-navbar.png" width="15%" id="check-in"/>
        </a>
        <a href="/images/atos-inbox.png" target="_blank">
            <img src="/images/atos-inbox.png" width="15%" id="check-in"/>
        </a>
    </div>
    <br />
    <h3>Final Design:</h3>
    <br />
    <div className="checking-in-container">
    <p>Checking In:</p>
        <a href="/images/xguard-index.png" target="_blank">
            <img src="/images/xguard-index.png" id="final-design" />
        </a>
        <a href="/images/xguard-checkin.png" target="_blank">
            <img src="/images/xguard-checkin.png" id="final-design" />
        </a>
        <a href="/images/xguard-checkin1.png" target="_blank">
            <img src="/images/xguard-checkin1.png" id="final-design" />
        </a>
        <a href="/images/xguard-checkin2.png" target="_blank">
            <img src="/images/xguard-checkin2.png" id="final-design" />
        </a>
        <a href="/images/xguard-success.png" target="_blank">
            <img src="/images/xguard-success.png" id="final-design" />
        </a>
    </div>
    <br />
    <div className="checking-in-container">
        <a href="/images/xguard-intro.png" target="_blank">
            <img src="/images/xguard-intro.png" id="final-design" />
        </a>
        <a href="/images/xguard-index.png" target="_blank">
            <img src="/images/xguard-index.png" id="final-design" />
        </a>
        <a href="/images/xguard-account-details.png" target="_blank">
            <img src="/images/xguard-account-details.png" id="final-design" />
        </a>
        <a href="/images/xguard-navbar.png" target="_blank">
            <img src="/images/xguard-navbar.png" id="final-design" />
        </a>
        <a href="/images/xguard-messages.png"  target="_blank">
            <img src="/images/xguard-messages.png" id="final-design" />
        </a>
    </div>



    </Container>
    </>
    )
}