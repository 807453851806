import { useEffect } from 'react';

import Container from 'react-bootstrap/Container'

import './ChillZone.css'


export default function ChillZone() {
    // const vidRef=useRef();

    // useEffect(() => { vidRef.current.play(); },[]);

    // <video
    // src="/videos.mp4"
    // ref={ vidRef }
    // muted
    // autoPlay
    // loop 
    // />

    return(
    <>
    <br />
    <Container>
        <h1 id="pageTitle">Chill Zone</h1>
        <h5>user research for a social hangout application</h5>
    <hr />
    <br /><br />
        <h1>Introduction</h1>
    <br />
    <p>
        I completed a prototype for an application called “Chill Zone''. I purposefully chose to create this because I wanted to challenge and strengthen my visual design skill-set.
    </p>
    <br />
    <h3>Mission and Goal</h3>
    <br />
    <p>
         We are in an age where people are spending majority of their time either on the computer or on their phones. People have the ability to choose to watch streams online, join group video calls, chat rooms, and play games. However, doing all those things require multiple applications to run at the same time.
         <br />
         <br />
         The mission and goal of this project was to present a design that created a very user friendly application while having multiple features built within it.
    </p>
    <br />
    <a href="/images/chillzone.gif" target="blank">
    <img src="/images/chillzone.gif" id="chillzoneGif" />
    </a>
    <br />
    <br />
    <h3>Color Palette</h3>
    <img src="/images/color-palette.png" width="400px" height="300px" id="color-palette" />
    <hr />
    <h3>Project Overview</h3>
    <br />
    <p>
        I wanted to create an interface for an application where users can hang out with their friends online while using a single application. Friends can come together in a group chat or video call. However during their time together, if they wanted to play games together, they have the option to sync other gaming applications onto their Chill Zone Account. Other features this application includes:
        <br />
    </p>
        <li>The capability to sync into their Spotify account</li>
        <li>Watch Twitch/YouTube Gaming channels that they follow</li>
        <li>Live Stream</li>
        <br />
    <br />
            <a href="/images/login-desktop.png" target="blank">
            <img src="/images/login-desktop.png" id="login-desktop"/>
            </a>
            <a href="/images/login-mobile.png" target="blank">
            <img src="/images/login-mobile.png" id="login-mobile" />
            </a>

            <a href="/images/dashboard-desktop.png" target="blank">
            <img src="/images/dashboard-desktop.png" id="dashboard-desktop" />
            </a>
            <a href="/images/friends-mobile.png" target="blank">
            <img src="/images/friends-mobile.png" id="friends-mobile" />
            </a>
    <br />  
            <a href="images/gamepage-desktop.png" target="_blank">
            <img src="/images/gamepage-desktop.png" id="gamepage-desktop" />
            </a>
    <hr />
    <h3>Targeted User</h3>
    <p>Before the pandemic, users have been searching for an application where people can come together and hang out. However since the COVID-19 pandemic began, Zoom, for instance, set a standard on how work and hanging out with friends can occur in large groups within a single application. On the other hand, Discord set a standard where gamers can come together and create groups to hang out with friends or other people with similar interests.</p>
    <br />
    <p>One thing these two applications have in common is an easy to use application. Due to it's easy experience, people routinely return and continuously use the application. Chill Zone was designed so people can come together to enjoy numerous entertainment options within one application. Chill Zone is aimed towards an audience ages 14 and up due to its easy to use interface.</p>
    <br />
    <a href="/images/twitch-desktop.png" target="blank">
    <img src="/images/twitch-desktop.png" width="1115px" height= "650px" alt="chill zone desktop rendering" id="twitch-desktop" img-fluid />
    </a>
    <br />
    <hr />
    <br />
    <h3>How to create revenue</h3>
    <br />
    <p>
    I purposefully left some “white space” because I believe that Ad revenue would be the best way to create a profit. Also, we would offer membership upgrade plans so they can:
    </p>
    <br />
    <li>Host a live stream</li>
    <li>Increase live stream account presence</li>
    <li>Personalize profiles</li>
    <li>Add personalized emoji</li>
    <li>Have multiple profiles</li>
    <li>Remove the ads</li>
    <br />
    <a href="/images/membership-desktop.png" target="blank">
    <img src="/images/membership-desktop.png" width="1115px" height= "650px" alt="membership page" id="spotify-desktop" />
    </a>
    <hr />
    <h3>Measuring Success</h3>
    <br />
    <p>
    In order to measure success, we would have to know that this product’s users are consistently returning. In order to measure that, we would have to have some sort of backend database or analytics storing and collecting each user’s cookies. Not only that, but we would have to determine if this product is gaining any sort of revenue through either membership upgrades or by ad revenue.
    </p>
    
    </Container>
    </>
    )
}