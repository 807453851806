import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import './Blog.css'

export default function Blog() {


    return (
    <Container>
    <br /><br />
    <h1>My Blog</h1>
    <br /><br />
        <Row>
        <Col>
        <Card>
            <Row className="justify-content-md-center">
                <Col xs={12} sm={4} md={4} id="test">
                    <img src="/images/hospital.png" alt="image of me in the hospital" id="hospital" img-fluid />
                </Col>
            <Card.Body id="chillZoneBody">
                <h4>My journey into the tech world</h4>
                    <Card.Text id="journey-text">
                    On December 18, 2009 at approximately 11:00 pm, Elk Grove Police Officers responded to a report of a man who had just been shot....
                    </Card.Text>
            <Button variant="secondary" href="/Tech">Read More</Button>
            </Card.Body>
            </Row>
            <Card.Footer>Updated June 23, 2022</Card.Footer>
        </Card>
        </Col>
        </Row>
        
    </Container>
    )
}