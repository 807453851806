import Container from 'react-bootstrap/Container'

import './Tech.css'

export default function Tech() {
    return (
        <Container>
        <br /><br />
        <h1>My journey into the tech world.</h1>
        <p id="subtitle-tag">By: Andrew Tapalla</p>
        <br />
            <img src="/images/family.png" id="family-image" alt="image of me and my family"/>
        <br />
            <p>On December 18, 2009 at approximately 11:00 pm, Elk Grove Police Officers responded to a report of a man who had just been shot. Officers responded and discovered that the victim was a young adult suffering from a single gun-shot wound. After investigation, it was revealed  that two subjects attempted to crash the party and were denied entry. The suspects had felt disrespected after being denied. Within minutes of driving away, the subjects returned and as they drove by fired shots into a group of people standing in the front of the residence.
        </p>
        <br />
        <p>
            It was at that time where the young male adult was struck. That man who was shot was me - Andrew Tapalla. At that moment, I became a statistic and ended up as an innocent victim of a drive-by shooting. After a short car chase and a high-risk traffic stop, the two suspects were detained.
        </p>
        <br /><br />
        <a href="images/hospital.png" target="_blank">
        <img src="images/hospital.png" id="hospital-image" alt="image of me in the hospital after being shot"/>
        </a>
        <p id="image-text">At the hospital the night after being shot</p>
        <br /><br />
        <p>
            On November 12, 2013 after a long and stressful two week jury trial of testimonies and finger-pointing, the jury had finally reached a verdict. The driver of the vehicle was found guilty and convicted of assault with a semiautomatic firearm, knowingly permitting another person to discharge a firearm from his/her vehicle, and carrying a concealed firearm. He was sentenced 3-5 years in prison, with the possibility of parole. The shooter was also found guilty and was convicted of discharging a firearm from a motor vehicle and assault with a semiautomatic firearm. He was sentenced 20 years to life, with the possibility of parole.
        </p>
        <br />
        <p>
            This was almost three years in the making. I wanted answers, I wanted justice, and most importantly, I wanted closure. After being escorted out of the courthouse by Sacramento County Sheriffs, I was finally able to breathe again. After digesting and evaluating what just had occurred, I had finally come to realize what my sense of purpose was. I came to understand that my sense of purpose was to help people and do my absolute best to positively impact my community.
        </p>
        <br />
        <p>
            As 5 years flew by, on April 2017, I received my first job offer to work for Washington State Department of Corrections as a Correctional Officer. My first month working for corrections, I was beyond excited to finally become something I worked so hard for. I was excited to finally have the opportunity to change someone’s life. As the days, weeks, and months go by, I’m realizing that I was feeling incredibly lonely living within the beautiful Eastern side of Washington. I was truly sad that I had no one to share this joy I experienced while witnessing all the scenic views of the Pacific Northwest. As more weeks go by, I experienced my first holiday season alone. At that moment, I felt like I hit rock bottom. I truly felt lonely and empty. That’s when I finally had enough of being alone and being away from friends and family. 
        </p>
        <br />
        <a href="images/ahcc.png" target="_blank">
        <img src="images/ahcc.png" id="ahcc-image" alt="photo of me after completing washington state department of corrections academy"/>
        </a>
        <p id="image-text">Successful completion of the Washington State Department of Corrections academy.</p>
        <br />
        <p>
            That’s when I began to pray to God for help again. Every night and every moment I had while I was at work, and every Sunday, I kept praying for a new opportunity to serve. I wanted to share my joy, excitement, and successes with people I love.
        </p>
        <br />
        <p>
            After months of prayer, I finally received an answer I’ve been praying so hard for. I received an email from Nevada Department of Corrections that I had passed all my exams for the hiring process and was offered a position. Words cannot express how excited and happy I was when I received the email. I was finally moving to Las Vegas, where I was close to friends and family and into a city that I was familiar with. At that moment, that was when I knew Las Vegas was going to be the town where I made a difference in. 
        </p>
        <br /><br />
        <a href="images/ndoc.png" target="_blank">
        <img src="images/ndoc.png" id="ndoc-image" alt="photo of my after completing nevada department of corrections academy"/>
        </a>
        <p id="image-text">Post Nevada Department of Corrections academy gradution photo.</p>
        <br /><br />
        <p>
            It is now July 2021 and my daughter, Alaina Tapalla, is 2 months old. Since she came into my life, I had dread going back into work. Not because I hated what I did, but because I was fearful of not coming home or bringing something biologically home. Something as small as getting poked by a “dirty needle” was something I was fearful of because I did not want to bring home Hepatitis A through C, HIV, or any other bloodborne diseases back to my family. Since I was waking up every morning dreading work, I had to make a decision. I was at a crossroad in my life where I had to make another life decision. Do I continue on this path, which I worked so hard to get into? Or do I make a career change to watch my daughter grow up?. I was so torn because I desperately wanted to make a difference and serve others. I was desperately looking at any option within the law enforcement or medical field. How do I help change someone’s life, if they do not want to change themselves? It was close to impossible to change an inmate’s life around while working within the corrections system. Inmates will not change unless they purposefully seek help. Due to that mindset, my morale and motivation was diminishing. 
        </p>
        <br />
        <a href="images/wedding.png" target="_blank">
        <img src="images/wedding.png" id="wedding" alt="post wedding family photo"/>
        </a>
        <p id="image-text">Wedding day family portrait</p>
        <br /><br />
        <p>
            My older brother played a significant role in my career change. He opened up my eyes and helped me realize I was looking at career choices through a small straw. Due to my ignorance, I firmly believed the only ways I could give back was through public service. At that moment, my mind opened up to the idea that I would be working with clients and businesses to work on products that could potentially change someone’s life. That was when I knew I was meant to serve with a different purpose. By changing career fields and going into a field where people seek help, it has reignited my motivation and drive to continue making a difference. The challenges of learning how to code, to design and landing a career has been by far the steepest mountain I have had to climb in my life, but I know I will make it.
        </p>
        <br />
        <img src="images/self-portrait.png" id="self-portrait" alt="self portrait"/>
        <br /><br />
        <p>
            So, this is me, Andrew Tapalla - I have perseverance and grit. I am motivated, ethical, and self-guided. I haven proven on the field that I can easily adapt to any stressful situation. With my selfless and motivated mindset, I can reassure to everyone that I will make a difference in helping a person, company, or community grow. <b>This I promise.</b>
        </p>
        </Container>
    )
}