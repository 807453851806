import emailjs from 'emailjs-com'
import { useRef } from 'react';

import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export default function Contact() {
    const form = useRef();

    const sendEmail= (e) => {
        e.preventDefault();

        emailjs.sendForm('service_u3shsal', 'template_j1ylvjj', form.current, 'B7k1S8XRLntPunrC1')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      };

    return (
    <>
    <br>
    </br>
    <br />
    <center><h1>Contact Me</h1></center>
    <br />
    <center><p>Where you can find me:</p>
    <a href="https://www.linkedin.com/in/andrewtapalla/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/linkedin.png'} height="35" width="35" alt="my linkedin account"/></a>
    <div className="divider"></div>
    <a href="images/designer.pdf" target="_blank"><img src={process.env.PUBLIC_URL + '/images/document.png'} height="35" width="35" alt="resume" /></a>
    <div className="divider"></div>
    <a href="https://www.andrewtapalla.com" target="_blank"><img src={process.env.PUBLIC_URL + '/images/portfolio.png'} height="35" width="35" alt="my linkedin account"/></a>
    <div className="divider"></div>
    <a href="https://github.com/andrewtapalla" target="_blank"><img src={process.env.PUBLIC_URL + '/images/github.png'} height="35" width="35" alt="my github site" /></a>
    <div className="divider"></div></center>
    
    <br /><br />
    <Container>
    <form ref={form} onSubmit={sendEmail}>
        <Row className="mb-3">
            <Col className="firstName-col">
                <label for="firstName">First Name*</label>
                <input type="text" name="firstName" className="firstNameBox" />
            </Col>
            <Col className="lastName-col">
                <label for="lastName">Last Name*</label>
                <input type="text" name="firstName" className="lastNameBox" />
            </Col>
        </Row>
        <Row className="mb-3">
            <label for="email">Email*</label>
            <input type="email" name="email" className="infoBox" />
        </Row>
        <Row className="mb-3">
            <label for="phonenumber">Phone*</label>
            <input type="phone" name="number" className="infoBox" />
        </Row>
            <Row className="mb-3">
            <label for="message">Message</label>
            <textarea name="message" className="infoBox" />
        </Row>
            <input type="submit" value="Send" className="contactBtn" />
        </form>
    <br />
    <br />
    </Container>
</>
    );
};