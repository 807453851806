import Container from 'react-bootstrap/Container'


import './LVJJ.css'

export default function LVJJ() {
    return(
    <>
    <br />
    <Container>
        <h1 id="pageTitle">Las Vegas Jiu-Jitsu</h1>
        <img src="../images/lvjj.png" width="1050px" height="550px" alt="LVJJ on a desktop" id="lvjj"></img>
        <h1>Introduction</h1>
        <br />
        <p>
            I chose to create this webpage for the Las Vegas Jiu-Jitsu team due to the owner, Cresencio Zamora, creating and building this team with his own time and money. He is a big supporter of police departments across the country adopting Brazilian Jiu-Jitsu as a standard. Due to his very inspiring and motivational reasonings, I wanted to give back to him due to all that he has done for all law enforcement officers within the Las Vegas Valley.
        </p>
        <br />
        <hr />
        <img src="../images/wireframe.png" id="wireFramePhoto" alt="low-fidelity wireframe" img-fluid></img>
        <br /><br />
        <h3>Project Overview</h3>
        <br />
        <p>
            The purpose of this website was to challenge myself into learning more Front-End languages. Before this project, I only knew HTML, CSS and JavaScript. By challenging myself, it significantly helped improve my ability to code using React.

            Not only that, but with this challenge, it led me to learning Figma. As a result, by learning Figma, it tremendously helped me become a better Front-End Developer and UI/UX Designer.
        </p>
        <br />
        <hr />
        <h2>Challenges</h2>
        <br />
        <div className="responseContainer">
        <br />
        <h4>Responsive Web Design</h4>
        <img src="../images/lvjj2.png" img-fluid id="responsePhoto" alt="various LVJJ images with CSS responsiveness implemented"></img>
        <p>
            One challenging obstacle I ran into while creating this webpage was creating this page to look presentable and easy to use on all devices. Not only that, but while learning HTML and CSS at the beginning of the program, I did not understand responsive web designing at first. Considering this is also my first time using React, I had to figure out how to properly embed expressions and specify attributes using JSX.
        </p>
        <br />
        <h5>Solution</h5>
        <p>
            I had to revisit CSS responsive designs, media queries and bootstrap. I decided to relearn it at a pace where I can easily understand it. I had to basically continuously create mistakes and “break” the webpage in order to have a working design. After spending approximately 2 days, I was able to implement a UI that was easy to read and interact with, while being able to look presentable on a different device.
        </p>
        <br />
        <h5>Result</h5>
        <p>
            Even though creating and designing a webpage that has smooth response continues to take a lot of time and effort, I have a higher and deeper understanding when to use it. I now understand how important it is to use media queries while also implementing React-Bootstrap.
        </p>
        <br />
        </div>
        <br />
        
        <div className="contactFormContainer">
        <br />
        <h4>Creating a Contact Form that works</h4>
        <br />
        <p>
            The most challenging task while designing this webpage was creating a contact form that properly functions.
        </p>
        <img src="../images/contact.png" img-fluid id="contactPhoto" alt="LVJJ Contact Page"></img>
        <br />
        <h5>Solutions</h5>
        <br />
        <p>
            At first, I had the form properly working while only using HTML and CSS, but as soon as I tried to implement it onto React, it immediately broke. I had to start from the beginning and learn how to embed expressions in JSX and learn more about hooks in react. After three days of constant failure and breaking, I discovered that I needed a service ID and a public key that connects to an email. With a key and ID, along with a Javascript code, it began to properly function.
        </p>
        <br />
        <h5>Result</h5>
        <p>
        As a result, I was able to implement a code that properly functioned using react that sends a proper contact email to the client’s personal email address.
        </p>
        <br />
        </div>
    </Container>
    </>
    )
}