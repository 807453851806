import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'

import './FlappyBlog.css'

export default function FlappyBlog() {
    return(
    <>
    <br /><br />
        <Container className="flappyContainer">
        <h1 className="pageTitle">FlappyBird Browser Game</h1>
        <br />
        <div className="introContainer">
                <h1 className="introTitle">
                    Introduction
                </h1>
                <p className="test">
                    This FlappyBird JavaScript Browser Game was my first ever project that I have ever created. It was tremendously challenging to me at first. The reason why it was very challenging was because of how difficult it was for me to understand JavaScript syntax, variables, functions, etc.
                </p>
            </div>
        <br />
        <br />
        <Row>
        <Col>
            <div id="flappyImg">
                <img src="../images/flappybird.png" height="650px" width="550px" id="flappyImg1" img-fluid></img>
            </div>
        </Col>
        <Col>
            <br />
            <div className="projectOverViewContainer">
            <h4>Project Overview</h4>
            <p>
            </p>
            <p>
                The purpose of this game is to have the bird pass through as many pipes as possible without touching the pipes. This game is meant to be competitive amongst users. The higher the score, the more bragging rights the winner has.
            </p>
            <p className="test">
                • Our instructors instructed us to create an in-browser game to challenge our skills that we learned using HTML, CSS, and JavaScript.
                </p>
                <p className="test">
                • The game must be winnable or keep score. The game must be deployed and ran in a web browser
                </p>
            </div>
        </Col>
        </Row>
        <br />
        <br />
        <h1>Challenges</h1>
        <br />
        <Card id="challengeCard" border="dark">
        <h4>Rendering Pipes</h4>
        <br />
        <Card.Title>Challenge</Card.Title>
        <p>
            The most challenging part of this entire project for myself was creating a function for the pipes to appear and for it to also disappear after passing a certain point.
        </p>
        <br />
        <Card.Title>Solution</Card.Title>
        <Card.Body>
            I had to understand that I had to use CSS along with my JavaScript code for the pipes to appear and disappear. To have both the north and south pipes appear, I had to create a north pipe in CSS first. Once that was done, I had the north and south pipes render at the same time within the same function. The pipes moving will constantly move -4 pixels until it reaches the left border. I had to create a new function “Math.random()” on the south pipe, in order to have the pipes generate random height.
        </Card.Body>
        <br />
        <Card.Title>Result</Card.Title>
        <Card.Body>
            As soon as the game starts, the pipes will render behind the right border, and the pipes will constantly move at the same rate of speed until it reaches the left border, where it will appear to disappear. The south pipe now appears to generate a random height due to a new function called. Due to that function, the game appears to be constantly moving with random pipe heights until a collision occurs.
        </Card.Body>
        </Card>
        <br />
        <Card id="challengeCard" border="dark">
            <h4>Bird Collision</h4>
            <br />
            <Card.Title>Challenge</Card.Title>
            <Card.Body>
            The next challenge I endured was trying to create a function for the bird collision with the pipes. At first, I had a difficult time trying to make the bird collision more sensitive. The collision would constantly occur after half of the bird was already sitting on the pipe. The collision should occur as soon as the bird touches either the north or south pipe.
            </Card.Body>
            <Card.Title>Solution</Card.Title>
            <Card.Body>
            After many trial and errors, I realized that all I had to do was get the size of the bird and pipes I made in CSS and implement their specific sizes into an “if” statement. I had to know the size of the bird image and the width of the pipes. For example, if the bottom of the bird surpassed the pipe width, then the game ends.
            </Card.Body>
            <br />
            <Card.Title>Results</Card.Title>
            <Card.Body>
            If the bottom of the bird touched the ground or either pipes, then the game would end instantly that would then render how many pipes the player passed.
            </Card.Body>
        </Card>
        <br />
        <br />
        </Container>
    </>
    )
}