import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import CardGroup from 'react-bootstrap/CardGroup'



export default function Projects() {
    return (
        <>
    <Container>
        <br /><br />
      <h1>Projects</h1>
        <div id="projectContainer">
        <Row>
        <Col>
        <Card className="projectCard" border="dark">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={4} md={4} id="test">
                    <img src="/images/xguard-intro.png" alt="lvjj online shop rendering" id="atos-image" img-fluid />
                </Col>
            <Card.Body id="chillZoneBody">
                <h4>X-Guard</h4>
                <Card.Subtitle className="mb-2">Figma, Photoshop</Card.Subtitle>
                    <Card.Text>
                     A Business Management Software Design for Gyms.
                    </Card.Text>
            <Button variant="primary" href="/XGuard">Click Here</Button>
            </Card.Body>
            </Row>
            <Card.Footer>Updated July 31, 2022</Card.Footer>
        </Card>
        </Col>
        </Row>  
        <br /><br />
        <CardGroup>
            <Card className="projectCard" border="dark">
                <Row className="justify-content-md-center">
                    <Col>
                        <img src="/images/rec-logo.png" alt="recollection in chrisitianity logo" id="rec-image" img-fluid />
                    </Col>
                <Card.Body>
                    <h4>Recollection in Christianity</h4>
                    <Card.Subtitle className="mb-2">Wordpress, Photoshop</Card.Subtitle>
                        <Card.Text>
                        Redesigned the entire user interface of the youth group's webpage.
                        </Card.Text>
                <Button variant="primary" href="/Rec">Click Here</Button>
                </Card.Body>
                </Row>
                {/* <Card.Footer>Updated June 14, 2022</Card.Footer> */}
            </Card>
            <div className="divider" />
            <Card className="projectCard" border="dark">
                <Row className="justify-content-md-center">
                    <Col>
                        <img src="/images/lvjj-desktop.png" alt="lvjj online shop rendering" id="lvjj-online-image" img-fluid />
                    </Col>
                    <Card.Body>
                        <h4>Las Vegas Jiu-Jitsu Online Shop</h4>
                        <Card.Subtitle className="mb-2">Adobe XD, FigJam</Card.Subtitle>
                            <Card.Text>
                            After creating the Las Vegas Jiu-Jitsu team page, I wanted to start creating prototypes for the team's online apparel shop.
                            </Card.Text>
                        <Button variant="primary" href="/LVJJ-Shop">Click Here</Button>
                    </Card.Body>
                </Row>
                {/* <Card.Footer>Updated May 21, 2022</Card.Footer> */}
            </Card>
            <div className="divider" />
            <Card className="projectCard" border="dark">
                <Row className="justify-content-md-center">
                    <Col>
                        <img src="/images/splash-image.png" alt="chill zone prototype rendering"  id="chillzone-image" img-fluid />
                    </Col>
                <Card.Body>
                    <h4>Chill Zone</h4>
                    <Card.Subtitle className="mb-2">Figma</Card.Subtitle>
                        <Card.Text>
                        I created a prototype to challenge my UI/UX skillset.
                        </Card.Text>
                <Button variant="primary" href="./ChillZone">Click Here</Button>
                </Card.Body>
                </Row>
                {/* <Card.Footer>Updated May 04, 2022</Card.Footer> */}
            </Card>
        </CardGroup>
        <br /><br /><br />
        <CardGroup>
        <Card className="projectCard" border="dark"> 
            <Row className="justify-content-md-center">
                <Col>
                    <img src="/images/flappybird.png" width="250px" height= "350px" id="flappybirdPhoto" alt="flappy bird game screenshot" img-fluid />
                </Col>
                <Card.Body id="flappybirdBody">
                    <h4>FlappyBird</h4>
                    <Card.Subtitle className="mb-2">JavaScript, Netlify</Card.Subtitle>
                        <Card.Text>
                        I decided to recreate the popular mobile game FlappyBird by Dong Nguyen for a project in my program.
                        </Card.Text>
                <Button variant="primary" href="./FlappyBlog">Click Here</Button>
                </Card.Body>
            </Row>
            {/* <Card.Footer>Updated April 22, 2022</Card.Footer> */}
        </Card>
        <div className="divider" />
        <Card className="logoGameCard" border="dark">
            <Row className="justify-content-md-center">
                <Col>
                        <img src="/images/logoGame.png" width="200px" height= "350px" id="logoPhoto" alt="screen shot of logo game on mobile" img-fluid />
                </Col>
                <Card.Body id="logoGameBody">
                    <Card.Title>Logo Game</Card.Title>
                    <Card.Subtitle className="mb-2">MongoDB, Express, React, Node, AWS, Heroku</Card.Subtitle>
                    <Card.Text>
                        This game was created after the idea of the game 'Wordly' as a MERNStack project for my course at UNLV.
                    </Card.Text>
                    <Button variant="primary" href="./LogoGame" >Click Here</Button>
                </Card.Body>
            </Row>
            {/* <Card.Footer>Updated April 30, 2022</Card.Footer> */}
        </Card>
        <div className="divider" />
        <Card className="projectCard" border="dark">
            <Row className="justify-content-md-center">
                    <Col>
                        <img src="/images/lvjjPhoto.png" width="290px" height= "350px" id="flappybirdPhoto" alt="screenshot of las vegas jiu-jitsu webpage on dekstop" img-fluid />
                    </Col>
                <Card.Body id="flappybirdBody">
                    <h4>Las Vegas Jiu-Jitsu</h4>
                    <Card.Subtitle className="mb-2">React, React-Bootstrap, AWS</Card.Subtitle>
                    <Card.Text>
                        I created this webpage for the LVJJ Team using Figma to design the webpage and used React and React-Bootstrap for my front-end development.
                    </Card.Text>
                    <Button variant="primary" href="./LVJJ">Click Here</Button>
                    <div className="divider"></div>
                </Card.Body>
            </Row>
            {/* <Card.Footer>Updated April 25, 2022</Card.Footer> */}
        </Card>

        <br />
        </CardGroup>
        {/* <Row>
        <Col>
        <Card>
            <Card.Body>
                <Card.Title>Minecraft Server</Card.Title>
                <Card.Subtitle className="mb-2">Linux, Ubuntu</Card.Subtitle>
                <Card.Text>
                    I am currently in the process of creating and maintaining a server within the popular Microsoft game Minecraft.
                </Card.Text>
                <Button variant="primary" href="#" disabled>Click Here</Button>
            </Card.Body>
        </Card>
        </Col>
        </Row> */}
    </div>
    </Container>

    </>
    )
}