import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Projects from './components/Projects'
import Blog from './components/Blog/Blog'
import Splash from './components/Splash'
import Contact from './components/Contact'
import FlappyBlog from './components/FlappyBlog/FlappyBlog';
import LVJJ from './components/LVJJ/LVJJ';
import LogoGame from './components/LogoGame/LogoGame';
import ChillZone from './components/ChillZone/ChillZone';
import Shop from './components/LVJJ-Shop/Shop';
import Tech from './components/Blog/Tech'
import Rec from './components/Rec/Rec'
import XGuard from './components/XGuard/XGuard'


import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
      <Navbar.Brand href="/">Andrew Tapalla's Blog</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
      </Nav>
      <Nav>
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/Projects">Projects</Nav.Link>
      <Nav.Link href="/Blog">Blog</Nav.Link>
      <Nav.Link href="/Contact">Contact</Nav.Link>
      </Nav>
      </Navbar.Collapse>
      </Container>
      </Navbar>

      

        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path='/FlappyBlog' element={<FlappyBlog />} />
          <Route path='/LVJJ' element={<LVJJ />} />
          <Route path='/LogoGame' element={<LogoGame />} />
          <Route path='/ChillZone' element={<ChillZone />} />
          <Route path='/LVJJ-Shop' element={<Shop />} />
          <Route path='/Tech' element={<Tech />} />
          <Route path='/Rec' element={<Rec />} />
          <Route path='/XGuard' element={<XGuard />} />

      </Routes>
      </BrowserRouter>
      <br />
      <br />
      <hr />
      <br />
      <br />
      © Andrew Tapalla, 2022
    </div>
    
  );
}

export default App;
