import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './Rec.css'
export default function Rec() {
    return(
    <Container>
        <br /><br />
        <h1>Recollection in Christianity</h1>
        <br /><br />
        <p>Recollection in Christianity (Rec) is a Catholic retreat movement in Sacramento, CA. The movement has grown to touch the youth of many different parishes and communities by teaching the importance of God, family, and friends. The design here was to brand this retreat movement for parents and the youth. I led the designing process for this project and created a brand identity.</p>
        <br />
        <img src="images/rec.gif" id="recollection-image" />
        <br /><br /><br /><br />
        <Row>
            <Col>
                <a href="images/recollection2-desktop.png" target="_blank">
                    <img src="images/rec-desktop.png" id="recollection2-image" />
                </a>
            </Col>
            <Col>
                <a href="images/recollection-desktop.png" target="_blank">
                    <img src="images/rec2-desktop.png" id="recollection3-image" />
                </a>
            </Col>
        </Row>
        <br /><br /><br />
        <Row id="mobile-row">
                <Col>
                        <img src="images/mobile1.png" id="mobile-1"/>
                </Col>
                <Col>
                        <img src="images/mobile2.png" id="mobile-2"/>
                </Col>
                <Col>
                        <img src="images/mobile3.png" id="mobile-3"/>
                </Col>
        </Row>
    </Container>
    )
}