import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export default function Splash() {
    return (
        <Container>
        <br /><br />
        <h1>Andrew's Blog</h1>
        <h4>UI/UX Designer</h4>
        <hr />
        <br /><br /><br />
        <Row>

        <Col>
        <Card className="splashCard" border="dark">
            <Card.Body>
                <Card.Title id="cardBuffer">Projects</Card.Title>
                <Card.Text>
                    Come view my Project Showcase here
                </Card.Text>
                <Button variant="primary" href="/Projects">Click Here</Button>
                </Card.Body>
        </Card>
        </Col>
        
        <br />
        <Col>
        <Card className="splashCard" border="dark">
            <Card.Body>
                <Card.Title id="cardBuffer">Blog</Card.Title>
                <Card.Text>
                    My blogs about my coding journey begins here! 
                </Card.Text>
                <Button variant="primary" href="/Blog">Click Here</Button>
                </Card.Body>
        </Card>
        </Col>
        </Row>
        <br />
        <br />
        <Row>
        <Col>
        <Card className="splashCard" border="dark">
            <Card.Body>
                <Card.Title id="cardBuffer">Contact Me</Card.Title>
                <Card.Text>
                    Feel free to contact me to work on projects. Get in touch with the info below.
                </Card.Text>
                <Button variant="primary" href="/Contact">Click Here</Button>
                </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>
    )
}