import Container from 'react-bootstrap/Container'

import './Shop.css'

export default function Shop() {
    return(
    <>
    <br />
    <Container>
        <h1>LVJJ Apparel Shop</h1>
        <h5>user interface for an easy ecommerce experience</h5>
    <hr />
    <br />

    <br />
    <a href="/images/lvjj-high-fidelity.png" target="blank">
    <img src="/images/lvjj-high-fidelity.png" width="70%" height= "50%" alt="lvjj journey map rendering" id="lvjj-index" img-fluid />
    </a>
    <br /><br />
    <h3>Mission and Goal</h3>
    <br />
    <p>
        The main mission of this product was to create an easy experience shopping online, while designing the website with a minimalistic design. The goal is to create a database that would easily save a returning user's information so shopping can be quick and easy. LVJJ needed help with creating an apparel website that would effectively sell their products. I wanted to create a website that was easy to navigate, effective, and simple in design.
    </p>
    <br />
    <hr />
    <br />
    <h3>Project Overview</h3>
    <br />
    <p>
        I wanted to implement my own personal experiences and translate it onto a product. As a designer for the LVJJ Apparel page, I wanted the apparel shop to be very simple, with very minimal visual noise, while being able to buy a product easy and fast so users can have a better experience. I've observed that that easier things were to look at and how fast it was to checkout and buy an item, the more likely I would be a returning customer.
    </p>
    <a href="/images/lvjj-journey-map.png" target="blank">
    <img src="/images/lvjj-journey-map.png" width="1015px" height= "650px" alt="lvjj journey map rendering" id="journey-map" img-fluid />
    </a>
    <br /><br />
    <h3>Wireframe</h3>
    <br />
    <p>The goal of the home page was to design this project to have the user instantly notice the minimal interface of the website without being overwhelmed with the amount navigation links and images on the webpage.</p>
    <br />
    <a href="/images/lvjj-low-fidelity.gif" target="blank">
    <img src="/images/lvjj-low-fidelity.gif" width="50%" height= "50%" alt="lvjj journey map rendering" id="journey-map" img-fluid />
    </a>
    <br /><br />
    <hr />
    <br />
    <h3>Competitive Analysis</h3>
    <br />
    <p>
         I analyzed two random competitors, Shoyoroll and Hyperfly, and created notes regarding strengths and weaknesses of each webpage. I then implemented what I’ve learned onto my prototype to help create an easier shopping experience.
    </p>
    <a href="/images/swot-analysis.png" target="blank">
    <img src="/images/swot-analysis.png" width="90%" height= "90%" alt="analysis of two random competitors" id="swot-analysis" img-fluid />
    </a>
    <br />
    <br />
    <hr />
    <h3>How to add revenue</h3>
    <br />
    <a href="/images/account.png" target="blank">
    <img src="/images/account.png" width="30%" height= "50%" alt="lvjj journey map rendering" id="account" img-fluid />
    </a>
    <div className="divider"></div>
    <div className="divider"></div>
    <div className="divider"></div>
    <a href="/images/university-tee.png" target="blank">
    <img src="/images/university-tee.png" width="30%" height= "50%" alt="lvjj journey map rendering" id="university-tee" img-fluid />
    </a>
    <br /><br />
    <p>
        The client has his products made overseas, which in itself saves him signficant money. The prices of his basic apparel (basic gi and t-shirts) will not be raised for one specific reason. The only prices that will be raised will be the apparel the brand drops every quarter. The dropped products will not be resold, which will increase the value of the product.
    </p>
    <br />
    <h3>Measuring Success</h3>
    <br />
    <p>
        The most important factor of measuring success will be observing for returning customers. We would measure this by enabling cookies and also by creating a backend database to observe how many customers creates an account. Not only that, but we would also measure success by measuring how much revenue the client will make with his apparel. By simply monitoring his revenue, that would signify if his apparel is doing well.
    </p>
    </Container>
    </>
    )
}